import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-4">
      <nav className="container mx-auto flex justify-center space-x-6">
        <a href="#about-us" className="hover:underline">About Us</a>
        <a href="#how-to-join" className="hover:underline">How to Join</a>
        <a href="#squadrons" className="hover:underline">Squadrons</a>
        <a href="#join-discord" className="hover:underline">Discord</a>
      </nav>
      <p className="text-center mt-4">© 2025 Joint Task Force Heavy. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
