import { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Is there an age requirement?",
      answer: "HVY is a group for members 18 years and older. Applicants must meet this age requirement.",
    },
    {
      question: "Can I join HVY if I’m in another group?",
      answer: "HVY prohibits multi-clan memberships. Members found in other DCS groups may have their membership revoked.",
    },
    {
      question: "Do you fly red aircraft (e.g., Hind, Mig-21)?",
      answer: "HVY operates NATO-aligned squadrons. You can use red aircraft on public servers but must join a blue squadron for membership.",
    },
    {
      question: "I’m new to DCS. Can I join?",
      answer: "Absolutely! Our members are happy to help new pilots learn and improve in their chosen airframe.",
    },
    {
      question: "Will I need to manage mods?",
      answer: "Beyond a few required mods and livery packs, setup is straightforward for participation in HVY.",
    },
    {
      question: "Are there certifications or qualifications?",
      answer: "New members complete a basic checkflight. Squadrons may have additional qualifications.",
    },
    {
      question: "How hardcore is HVY’s milsim approach?",
      answer: "HVY balances realism with fun. Real-life procedures are followed where appropriate, but it’s not overly strict.",
    },
    {
      question: "What policies should I know about?",
      answer: "HVY enforces zero tolerance for hate speech, racism, and harassment. Avoid political discussions.",
    },
  ];

  return (
    <section id="faq" className="py-8 scroll-mt-16">
      <h3 className="text-2xl mt-8 font-bold tracking-tight text-gray-900 sm:text-2xl">
        Frequently Asked Questions
      </h3>
      <dl className="space-y-6 divide-y divide-gray-900/10">
        {faqs.map((faq, index) => (
          <div key={index} className="pt-6">
            <dt>
              <button
                type="button"
                className="flex w-full items-start justify-between text-left text-gray-900"
                aria-expanded={activeIndex === index}
                onClick={() => toggleFAQ(index)}
              >
                <span className="text-base font-semibold leading-7">{faq.question}</span>
                <span className="ml-6 flex h-7 items-center">
                  <svg
                    className={`h-6 w-6 transition-transform ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d={`M12 ${
                        activeIndex === index ? "18" : "6"
                      }v12m6-6H6`}
                    />
                  </svg>
                </span>
              </button>
            </dt>
            {activeIndex === index && (
              <dd className="mt-2 text-base leading-7 text-black">{faq.answer}</dd>
            )}
          </div>
        ))}
      </dl>
    </section>
  );
};

export default FAQ;
