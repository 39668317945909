import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white p-4 sticky top-0 z-50">
      <nav className="container mx-auto flex items-center justify-between">
        {/* Left Section */}
        <div className="flex items-center space-x-6 flex-1 justify-start">
          <a href="#about-us" className="hover:underline text-center">
            About Us
          </a>
          <a href="#how-to-join" className="hover:underline text-center">
            How to Join
          </a>
          <a href="#squadrons" className="hover:underline text-center">
            Squadrons
          </a>
          <a href="#join-discord" className="hover:underline text-center">
            Discord
          </a>
        </div>

        {/* Center Section (Logo) */}
        <div className="flex-1 flex justify-center items-center">
          <img src="/logo.png" alt="Logo" className="h-12" />
        </div>

        {/* Right Section */}
        <div className="flex items-center space-x-6 flex-1 justify-end">
          <a href="#" className="hover:underline text-center">
            PERSCOM
          </a>
        </div>
      </nav>
    </header>
  );
};




export default Header;
