import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FAQ from "./faq";

const MainContent = () => {
  return (
    <>
      {/* Full-Width Image Header */}
      {/*}
      <div className="w-full">
        <img
          src="pics/nighthornet.jpg"
          alt="Header Image"
          className="w-full h-auto object-cover"
        />
      </div>
      */}

      {/* Temporary Event Banner */}
      <div className="w-full flex">
        <img
          src="pics/event1.jpg"
          alt="Event Image 1"
          className="w-1/2 object-cover"
          style={{ height: 'auto', maxHeight: '520px' }}
        />
        <img
          src="pics/event2.jpg"
          alt="Event Image 2"
          className="w-1/2 object-cover"
          style={{ height: 'auto', maxHeight: '520px' }}
        />
      </div>

      <main className="w-full min-h-screen px-6 py-12 bg-gray-100 bg-[url('bg.jpg')] bg-repeat bg-fixed bg-top">
      <section id="about-us" className="py-8 scroll-mt-16 flex flex-wrap lg:flex-nowrap items-center">
        {/* About Us Text Section */}
        <div className="w-full lg:w-2/3 pr-6">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">About Us</h1>
          <p className="text-black">
            Joint Task Force Heavy (HVY) is a global Digital Combat Simulator community with over 200 pilots from diverse backgrounds, including casual gamers, experienced commercial aviators, and former military pilots. Representing nearly every continent, HVY fields squadrons operating a full range of NATO aircraft. The group conducts regular squadron operations and dynamic, modern-era campaigns. No prior experience in a jet is required, as HVY members are always eager to assist and support one another. While the community spans multiple time zones, most operations and flight nights are scheduled around North American time zones (EST and PST).
          </p>
        </div>

        {/* Pictures Section */}
        <div className="w-full lg:w-1/3 grid grid-cols-2 gap-4 items-center">
          {/* Left Column: Two Images */}
          <div className="flex flex-col space-y-4">
            <div className="aspect-w-1 aspect-h-1">
              <img
                src="pics/119.png"
                alt="Squadron 119"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
            <div className="aspect-w-1 aspect-h-1">
              <img
                src="pics/A10.jpg"
                alt="A-10 Warthog"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>

          {/* Right Column: Three Images */}
          <div className="flex flex-col space-y-4">
            <div className="aspect-w-1 aspect-h-1">
              <img
                src="pics/rotor.jpg"
                alt="142nd AHC"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
            <div className="aspect-w-1 aspect-h-1">
              <img
                src="pics/F15.jpg"
                alt="F-15 Eagle"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
            <div className="aspect-w-1 aspect-h-1">
              <img
                src="pics/tide.png"
                alt="Blue Tide"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>



      </section>

      <section id="how-to-join" className="py-8 scroll-mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">How to Join</h2>
        <p className="text-black">
          Joining HVY is a simple process, but there are a few basic requirements you must meet to become a member:
        </p>

        <div className="mt-6 bg-gray-100 p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Basic Rules</h3>
          <ul className="space-y-4">
            <li>
              <h4 className="text-xl font-semibold text-gray-800">18+ Community</h4>
              <p className="text-black">
                HVY is an 18+ community. While we recognize that maturity isn't solely defined by age, this rule is in place to maintain the desired level of engagement and professionalism.
              </p>
            </li>
            <li>
              <h4 className="text-xl font-semibold text-gray-800">No Multi-Clanning</h4>
              <p className="text-black">
                To honor the effort invested in building this community, we require members to commit fully by not joining other DCS flying groups. This policy ensures loyalty and dedication within HVY.
              </p>
            </li>
            <li>
              <h4 className="text-xl font-semibold text-gray-800">Zero Tolerance Policy</h4>
              <p className="text-black">
                HVY strictly prohibits hate speech, racism, and related behavior. Applicants who cannot adhere to this standard should not apply.
              </p>
            </li>
          </ul>
        </div>
        
        <p className="mt-6 text-black">
          To apply for membership, use the Discord invite link at the bottom of this page, navigate to the #new-applications channel, and type "/apply" to complete your application using the PirateBot app.  
        </p>
      </section>

    {/* FAQ Section */}
    <FAQ />

      <section id="squadrons" className="py-8 scroll-mt-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Squadrons</h2>
        <p className="text-black mb-8">
          Joint Task Force Heavy has several squadrons, each with their own unique airframe and mission set. Those squadrons belong to three main battle groups - Air Combat Group (ACG), Marine Air Ground Task Force (MAGTF), and Carrier Air Wing 62 (CVW-62). Additionally, our Auxiliary Corps handles unique secondary duties. Take a look:
        </p>

        {/* Air Combat Group (ACG) */}
        <div className="mb-12">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Air Combat Group (ACG)</h3>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4 place-items-center">
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/472.webp" alt="472nd Fighter Squadron" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">472nd Fighter Squadron</h4>
              <p className="text-sm text-black">F-16C</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/484.webp" alt="484th Fighter Squadron" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">484th Fighter Squadron</h4>
              <p className="text-sm text-black">A-10C II</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/583.webp" alt="583rd Fighter Squadron" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">583rd Fighter Squadron</h4>
              <p className="text-sm text-black">F-15E</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/142.webp" alt="142nd Assault Helicopter Company" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">142nd Assault Helicopter Company</h4>
              <p className="text-sm text-black">Rotary</p>
            </div>
          </div>
        </div>

        {/* Marine Air Ground Task Force (MAGTF) */}
        <div className="mb-12">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Marine Air Ground Task Force (MAGTF)</h3>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4 place-items-center">
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/390.webp" alt="Marine Fighter Attack Squadron 390" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Marine Fighter Attack Squadron 390</h4>
              <p className="text-sm text-black">F/A-18C</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/290.webp" alt="Marine Attack Squadron 290" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Marine Attack Squadron 290</h4>
              <p className="text-sm text-black">AV-8B NA</p>
            </div>
          </div>
        </div>

        {/* Carrier Air Wing 62 (CVW-62) */}
        <div className="mb-12">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Carrier Air Wing 62 (CVW-62)</h3>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4 place-items-center">
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/119.webp" alt="Strike Fighter Squadron 119" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Strike Fighter Squadron 119</h4>
              <p className="text-sm text-black">F/A-18C</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/172.webp" alt="Fighter Squadron 172" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Fighter Squadron 172</h4>
              <p className="text-sm text-black">F-14B</p>
            </div>
          </div>
        </div>

        {/* Auxiliary Corps */}
        <div className="mb-12">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Auxiliary Corps</h3>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4 place-items-center">
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/blue.webp" alt="Blue Tide Demonstration Team" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Blue Tide Demonstration Team</h4>
              <p className="text-sm text-black">F/A-18C</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/23.webp" alt="23rd Aggressors Squadron" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">23rd Aggressors Squadron</h4>
              <p className="text-sm text-black">Aggressors</p>
            </div>
            <div className="text-center">
              <img className="mx-auto h-48 w-auto object-contain" src="squadrons/pl.webp" alt="Phantom Logistics" />
              <h4 className="mt-4 text-lg font-semibold text-gray-900">Phantom Logistics</h4>
              <p className="text-sm text-black">Civ</p>
            </div>
          </div>
        </div>
      </section>




      <section id="join-discord" className="py-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Join Our Community</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Discord Widget */}
          <div className="bg-gray-900 text-white rounded-lg shadow-md p-6 flex justify-center items-center">
            <iframe
              src="https://discord.com/widget?id=398677618985009152&theme=dark"
              width="350"
              height="500"
              allowTransparency="true"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              title="Discord Widget"
              className="rounded-lg"
            ></iframe>
          </div>

          {/* Discord Invite Button */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-md flex flex-col justify-center items-center">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Join the Conversation</h3>
            <p className="text-black mb-6 text-center">
              Click the button below to join our Discord server and become part of the community!
            </p>
            <a
              href="https://discord.gg/sPavY3ZZQH"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-indigo-600 hover:bg-indigo-500 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
            >
              Join Our Discord
            </a>
          </div>
        </div>
      </section>
    </main>
    </>
  );
};

export default MainContent;
